// contactsApi.ts
import { APINodeClient } from './apiNodeCore';
import * as nodeUrl from './nodeUrls';

const api = new APINodeClient();

// Fetch contacts from the backend
export const getLogs = async () => {
  return api.get(nodeUrl.GET_LOGS);
};

