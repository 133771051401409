// contactsActions.ts

import { debugLogsTypes } from './debugLogsTypes';

// Action to handle API success
export const contactsApiResponseSuccess = (actionType: string, data: any) => ({
  type: debugLogsTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// Action to handle API error
export const contactsApiResponseError = (actionType: string, error: string) => ({
  type: debugLogsTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});




// Action to fetch all contacts
export const getLogs = () => ({
  type: debugLogsTypes.GET_LOGS,
});
