import { APINodeClient } from "./apiNodeCore";
import * as nodeUrl from "./nodeUrls";

const api = new APINodeClient();

// // postForgetPwd
// const postFakeForgetPwd = (data: any) =>
//   api.create(nodeUrl.POST_FAKE_PASSWORD_FORGET, data);

// // postForgetPwd
// const postJwtForgetPwd = (data: any) =>
//   api.create(nodeUrl.POST_FAKE_JWT_PASSWORD_FORGET, data);

const nodeLogin = (data: any) => {
  console.log("login data ", data)
  return api.create(nodeUrl.NODE_LOGIN, data);
}

// const postJwtLogin = (data: any) => api.create(nodeUrl.POST_FAKE_JWT_LOGIN, data);

// // Register Method
// const nodeRegister = (data: any) => {
//   return api.create(nodeUrl.NODE_REGISTER, data);
// };

// // Register Method
// const postJwtRegister = (data: any) => {
//   return api.create(nodeUrl.JWT_REGISTER, data);
// };
// const changePassword = (data: object) => {
//   return api.update(nodeUrl.USER_CHANGE_PASSWORD, data);
// };

// postSocialLogin
const postSocialLogin = (data: any) => api.create(nodeUrl.SOCIAL_LOGIN, data);

// postGoogleLogin
const postGoogleLogin = (data: any) => api.get(nodeUrl.GOOGLE_LOGIN);

// postGoogleVerify
const getGoogleVerify = () => api.get(nodeUrl.GOOGLE_VERIFY);

// Captcha Verification
const postVerifyCaptcha = (data: any) => api.create(nodeUrl.VERIFY_CAPTCHA, data);

// gmail Access Permission
const gmailAccessPermission = (data: any) => api.get(nodeUrl.GOOGLE_LOGIN);

// gmail Permission verification  >>  to verify if gmail permission was provided by the user
const getGmailVerify = () => api.get(nodeUrl.GMAIL_VERFIY);

// logout route
const  logout = () => api.get(nodeUrl.NODE_LOGOUT);


export {
  // postFakeForgetPwd,
  // postJwtForgetPwd,
  nodeLogin,
  // postJwtLogin,
  // nodeRegister,
  // postJwtRegister,
  // changePassword,
  postSocialLogin,
  postGoogleLogin,
  getGoogleVerify,
  postVerifyCaptcha,
  getGmailVerify,
  logout
};
