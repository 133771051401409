// utils.ts
import { ActionRecord } from './maiActionTypes';

// maiActionsUtils.ts

import { MaiActionsState, AddNewActionsPayload, UpdateActionsPayload } from './maiActionTypes';



export const computeDeadlineTags = (action: ActionRecord): string[] => {
    const tags: string[] = [];
    
    if (!action.deadline) {
        tags.push('No deadline');
        return tags;
    }

    const deadlineDate = new Date(action.deadline);
    const today = new Date();

    // Calculate the difference in days
    const diffInTime = today.getTime() - deadlineDate.getTime();
    const diffInDays = Math.floor(diffInTime / (1000 * 3600 * 24));

    // Helper functions to calculate end of week, month, and quarter
    const getEndOfWeek = (date: Date) => {
        const day = date.getDay();
        const diff = 6 - day; // Sunday is the end of the week
        const endOfWeek = new Date(date);
        endOfWeek.setDate(date.getDate() + diff);
        return endOfWeek;
    };

    const getEndOfMonth = (date: Date) => {
        const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return endOfMonth;
    };

    const getEndOfQuarter = (date: Date) => {
        const quarter = Math.floor((date.getMonth() + 3) / 3);
        const endOfQuarter = new Date(date.getFullYear(), quarter * 3, 0);
        return endOfQuarter;
    };

    const endOfWeek = getEndOfWeek(today);
    const endOfMonth = getEndOfMonth(today);
    const endOfQuarter = getEndOfQuarter(today);

    if (diffInDays > 0) { // Past deadline
        if (action.status === 'completed'){
            if (diffInDays > 90) {
                tags.push('completed > 1 month');
            } else if (diffInDays > 30) {
                tags.push('completed last month');
            } else if (diffInDays > 7) {
                tags.push('completed last week');
            } else if (diffInDays === 1) {
                tags.push('completed yesterday');
            } else if (diffInDays === 0) {
                tags.push('completed today');
            }
        } else if (action.status !== 'dropped') {
            tags.push('overdue');
            if (diffInDays > 30) {
                tags.push('overdue > 1 month');
            } else if (diffInDays > 7) {
                tags.push('overdue > 1 week');
            } else if (diffInDays > 3) {
                tags.push('overdue > 3 days');
            } else if (diffInDays > 2) {
                tags.push('overdue > 2 days');
            } else if (diffInDays > 1) {
                tags.push('overdue > 1 day');
            } else {
                tags.push('overdue');
            }
        }
    } else { // Upcoming deadline
        const diffInFutureDays = Math.abs(diffInDays);
        if (deadlineDate.getTime() === today.getTime()) {
            tags.push('Due today');
        } else if (diffInFutureDays === 1) {
            tags.push('Due tomorrow');
        } else if (deadlineDate <= endOfWeek) {
            tags.push('Due this week');
        } else if (deadlineDate <= getEndOfWeek(new Date(endOfWeek.getTime() + 7 * 24 * 60 * 60 * 1000))) { // end of next week
            tags.push('Due next week');
        } else if (deadlineDate <= endOfMonth) {
            tags.push('Due this month');
        } else if (deadlineDate <= getEndOfMonth(new Date(endOfMonth.getFullYear(), endOfMonth.getMonth() + 1, 1))) { // end of next month
            tags.push('Due next month');
        } else if (deadlineDate <= endOfQuarter) {
            tags.push('Due this quarter');
        } else if (deadlineDate <= getEndOfQuarter(new Date(endOfQuarter.getFullYear(), endOfQuarter.getMonth() + 3, 1))) { // end of next quarter
            tags.push('Due next quarter');
        } else {
            tags.push('Due later');
        }
    }

    return tags;
};





export const compareTags = (currentTags: string[], previousTags: string[]): {
  toRemove: string[];
  toAdd: string[];
} => {
  const toRemove = previousTags.filter(tag => !currentTags.includes(tag));
  const toAdd = currentTags.filter(tag => !previousTags.includes(tag));

  return { toRemove, toAdd };
};




export const addNewActions = (state: MaiActionsState, payload: AddNewActionsPayload): MaiActionsState => {
  const newAllActions = { ...state.allActions };
  const newTags = { ...state.tags };
  // console.log("addNewActions before state", newAllActions)
  // console.log("addNewActions payload", payload)
  Object.values(payload).forEach((action) => {
  
  // Assuming payload.newActions is a JSON object with keys as action IDs and values as action detail objects
  // Object.values(payload).forEach((action) => {
    newAllActions[action.action_id] = action;
    // const deadlineTags = computeDeadlineTags(action);
    // deadlineTags.forEach((tag) => {
    //   if (!newTags['Deadline']) {
    //     newTags['Deadline'] = {};
    //   }
    //   if (!newTags['Deadline'][tag]) {
    //     newTags['Deadline'][tag] = new Set();
    //   }
    //   newTags['Deadline'][tag].add(action.action_id);
    // });

    // updateTagSet(newTags, 'Status', action.status, action.action_id);
    // updateTagSet(newTags, 'Priority', action.priority, action.action_id);
    // updateTagSet(newTags, 'Owner', action.name, action.action_id);
  });

  // console.log("addNewActions after change", newAllActions)

  return {
    ...state,
    allActions: newAllActions,
    tags: newTags,
  };
};


export const updateActions = (state: MaiActionsState, payload: UpdateActionsPayload): MaiActionsState => {
  const updatedAllActions = { ...state.allActions };
  const updatedTags = { ...state.tags };

  payload.updatedActions.forEach((updatedAction) => {
    const existingAction = updatedAllActions[updatedAction.action_id];
    const previousDeadlineTags = computeDeadlineTags(existingAction);
    const currentDeadlineTags = computeDeadlineTags(updatedAction);

    const { toRemove, toAdd } = compareTags(currentDeadlineTags, previousDeadlineTags);

    toRemove.forEach((tag) => {
      updatedTags['Deadline'][tag].delete(updatedAction.action_id);
    });
    toAdd.forEach((tag) => {
      if (!updatedTags['Deadline'][tag]) {
        updatedTags['Deadline'][tag] = new Set();
      }
      updatedTags['Deadline'][tag].add(updatedAction.action_id);
    });

    updateTagSet(updatedTags, 'Status', updatedAction.status, updatedAction.action_id);
    updateTagSet(updatedTags, 'Priority', updatedAction.priority, updatedAction.action_id);
    updateTagSet(updatedTags, 'Owner', updatedAction.name, updatedAction.action_id);

    updatedAllActions[updatedAction.action_id] = updatedAction;
  });

  return {
    ...state,
    allActions: updatedAllActions,
    tags: updatedTags,
  };
};

const updateTagSet = (tags: any, category: string, tag: string, action_id: string) => {
  if (!tags[category]) {
    tags[category] = {};
  }
  if (!tags[category][tag]) {
    tags[category][tag] = new Set();
  }
  tags[category][tag].add(action_id);
};

// notificationUtils.ts

export const generateNotificationMessage = (newActions: any[], updatedActions: any[]): string => {
    const newActionsCount = newActions.length;
    const updatedActionsCount = updatedActions.length;
    const totalActions = newActionsCount + updatedActionsCount;
  
    let notificationMessage = '';
  
    if (totalActions === 1) {
      if (newActionsCount === 1) {
        notificationMessage = `New action: ${newActions[0].title}`;
      } else {
        notificationMessage = `Updated action: ${updatedActions[0].title}`;
      }
    } else if (totalActions === 2) {
      if (newActionsCount === 1 && updatedActionsCount === 1) {
        notificationMessage = `New action: ${newActions[0].title}, Updated action: ${updatedActions[0].title}`;
      } else if (newActionsCount === 2) {
        notificationMessage = `New actions: ${newActions[0].title}, ${newActions[1].title}`;
      } else if (updatedActionsCount === 2) {
        notificationMessage = `Updated actions: ${updatedActions[0].title}, ${updatedActions[1].title}`;
      }
    } else if (totalActions > 2) {
      const newMsg = newActionsCount > 0 ? `${newActionsCount} new action${newActionsCount > 1 ? 's' : ''}` : '';
      const updatedMsg = updatedActionsCount > 0 ? `${updatedActionsCount} updated action${updatedActionsCount > 1 ? 's' : ''}` : '';
      notificationMessage = `${newMsg} ${newMsg && updatedMsg ? 'and' : ''} ${updatedMsg}`;
    }
  
    return notificationMessage.trim();
  };
  


  // Function to update an object by action_id
export function updateActionById(allActions: any[], actionId: any, updatedData: any) {
  // Find the index of the object with the specified action_id
  const index = allActions.findIndex(action => action.action_id === actionId);
  const newAllActions =  [...allActions];

  // If found, update the object with the new data
  if (index !== -1) {
      newAllActions[index] = updatedData;
      console.log(`Updated action with ID ${actionId}:`, allActions[index]);
      return newAllActions;
  } else {
      console.log(`Action with ID ${actionId} not found.`);
      return allActions;
  }
}
