
import { debugLogsTypes, DebugLogs } from "./debugLogsTypes";


const INIT_STATE = {
    logs: [
      {
        date: "Wed, 23 Oct 2024 15:17:53 +0530",
        processed_status: "PROCESSED",
        link_id: 14,
        subject: "Re: Please do the suggested code fix for Mentor AI",
        body: "Please get this done by eod",
        from: "Anil Pal <admin@dkafka.com>",
        to: "Vikas Varfa <vikas@dkafka.com>",
        thread_id: "192b8c4d6c320d3d",
        labels:  "['label1', 'label2']",
        new_updated: "updated",
        action_id: 5,
        title: "Please do the suggested code fix for Mentor AI",
        allocated_date: "2024-10-23",
        deadline: "2024-10-23",
        priority: "Medium",
        status: "In Progress",
        status_update_commentary: "{\"update\": \"Anil Pal has requested Vikas Varfa to complete the task by the end of the day\"}",
        mail_origin_type: null,
        latest_action_status_update_date: "Wed, 23 Oct 2024 15:17:53 +0530",
      },
      // Add other logs here based on your provided data...
    ],
    loading: false,
    error: null,
  };



  const debugLogsReducer  = (state = INIT_STATE, action: any): DebugLogs => {
    switch (action.type) {
    // Success handler for API responses
    case debugLogsTypes.API_RESPONSE_SUCCESS:
      console.log("log reducer success response")
      switch (action.payload.actionType) {
        case debugLogsTypes.GET_LOGS:
          // console.log("GET LOGS POST api success reducer trigerred ", action.payload.data)
          return {
            ...state,
            loading:  false,
            logs: action.payload.data,
          };
        default:
          return { ...state };
      }

    // Error handler for API responses
    case debugLogsTypes.API_RESPONSE_ERROR:
      console.log("log reducer error response:", action.payload.error);
      return {
        ...state,
        loading: false,
        error: typeof action.payload.error === 'string' ? action.payload.error : "An error occurred in debugs log reducer response",
      };

      // Actions to set loading state before API call
    case debugLogsTypes.GET_LOGS:
      console.log("get LOGS pre api reducer is triggerred ")
      return { ...state };


    default:
      return { ...state };

    }
  };

  export default debugLogsReducer;