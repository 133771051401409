import { AuthLoginActionTypes } from './authTypes';

// Common success
export const authLoginApiResponseSuccess = (actionType: string, data: any) => ({
  type: AuthLoginActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});

// Common error
export const authLoginApiResponseError = (actionType: string, error: string) => ({
  type: AuthLoginActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (user: any) => {
  return {
    type: AuthLoginActionTypes.LOGIN_USER,
    payload: { user },
  };
};

export const logoutUser = () => {
  return {
    type: AuthLoginActionTypes.LOGOUT_USER,
  };
};

export const socialLogin = (data: any, type: 'facebook' | 'google') => {
  return {
    type: AuthLoginActionTypes.SOCIAL_LOGIN,
    payload: { data, type },
  };
};

export const googleVerify = () => {
  return {
    type: AuthLoginActionTypes.GOOGLE_VERIFY,
  };
};

export const captchaVerify = (data: any) => {
  return {
    type: AuthLoginActionTypes.CAPTCHA_VERIFY,
    payload: { data },
  };
};

export const gmailVerify = () => {
  return {
    type: AuthLoginActionTypes.GMAIL_VERIFY,
  };
};
