import axios, { AxiosInstance } from "axios";
import config from "../config";

class APINodeClient {
  private axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: config.API_NODE_URL,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Request Interceptor
    this.axiosInstance.interceptors.request.use(
      (config) => {
        return config;
      },
      (error) => {
        console.error("Request error", error);
        return Promise.reject(error);
      }
    );

    // Response Interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response.data ? response.data : response;
      },
      (error) => {
        console.error("Response error", error.toJSON());
        let message;
        switch (error.response?.status) {
          case 500:
            message = "Internal Server Error";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          case 404:
            message = "Sorry! The data you are looking for could not be found";
            break;
          default:
            message = error.message || "An unknown error occurred";
        }
        return { error: true, message };
      }
    );
  }

  async get(url: string, params?: {}): Promise<any> {
    try {
      const response = await this.axiosInstance.get(url, { params });
      console.log("GET request successful", response);
      return response;
    } catch (error) {
      console.error("GET request failed", error);
      return error; // Returning the error object so that calling functions can handle it.
    }
  }

  async create(url: string, data?: {}): Promise<any> {
    try {
      const response = await this.axiosInstance.post(url, data);
      console.log("POST request successful", response);
      return response;
    } catch (error) {
      console.error("POST request failed", error);
      return error; // Returning the error object so that calling functions can handle it.
    }
  }

  async update(url: string, data?: {}): Promise<any> {
    try {
      const response = await this.axiosInstance.put(url, data);
      console.log("PUT request successful", response);
      return response;
    } catch (error) {
      console.error("PUT request failed", error);
      return error; // Returning the error object so that calling functions can handle it.
    }
  }

  async delete(url: string, config?: {}): Promise<any> {
    try {
      const response = await this.axiosInstance.delete(url, { ...config });
      console.log("DELETE request successful", response);
      return response;
    } catch (error) {
      console.error("DELETE request failed", error);
      return error; // Returning the error object so that calling functions can handle it.
    }
  }

  async updateWithFile(url: string, data: any): Promise<any> {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const response = await this.axiosInstance.put(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("PUT request with file successful", response);
      return response;
    } catch (error) {
      console.error("PUT request with file failed", error);
      return error; // Returning the error object so that calling functions can handle it.
    }
  }

  async createWithFile(url: string, data: any): Promise<any> {
    try {
      const formData = new FormData();
      for (const key in data) {
        formData.append(key, data[key]);
      }
      const response = await this.axiosInstance.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("POST request with file successful", response);
      return response;
    } catch (error) {
      console.error("POST request with file failed", error);
      return error; // Returning the error object so that calling functions can handle it.
    }
  }

  async triggerFileDownload(url: string): Promise<void> {
    try {
        // After validating or performing any checks, redirect to download URL
        window.location.href = config.API_NODE_URL + url;

    } catch (error) {
        console.error("Failed to trigger download", error);
    }
}


// Method to initiate SSE connection
startSSE(url: string, params: Record<string, any>, onMessage: (data: any) => void, onError?: (error: any) => void): EventSource {
  // const eventSource = new EventSource(`${config.API_NODE_URL}${url}`, { withCredentials: true });

  // Convert params object to query string
  const queryString = new URLSearchParams(params).toString();
  const eventSourceUrl = `${config.API_NODE_URL}${url}?${queryString}`;

  // Create EventSource with the full URL including query params
  const eventSource = new EventSource(eventSourceUrl, { withCredentials: true });

  eventSource.onmessage = (event) => {
    const parsedData = JSON.parse(event.data);
    onMessage(parsedData);
  };

  eventSource.onerror = (error) => {
    console.error("SSE error:", error);
    if (onError) {
      onError(error);
    }
    
    if (EventSource.CLOSED === 2) {
      console.log("SSE connection closed by the server");
      eventSource.close();
    }
    
    console.log("SSE connection closed due to unknown erro")

  };

  return eventSource;
}

stopSSE(eventSource: EventSource): void {
  eventSource.close();
  console.log("SSE connection closed");
}
  

}

export { APINodeClient };
