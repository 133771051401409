// myActionSaga.tsx
import { select, call, put, takeLatest, take, cancelled, cancel, fork, delay } from 'redux-saga/effects';
import { getAllActions, editActions as editActionsApi, createSSEChannel } from '../../api/maiActions'; // Adjust the path as needed
import {  } from './maiActionActions';
import { apiResponseSuccess, apiResponseError, addNewActions } from './maiActionActions';
import { APINodeClient } from '../../api/apiNodeCore';

import { showSuccessNotification, showErrorNotification } from "../../../src/helpers/NotificationContext"


const api = new APINodeClient();

function* fetchNewActions(action: any) {
    try {
      console.log("action.payload.actionsHistoryId", action.payload.actionsHistoryId)
      const response = yield call(getAllActions, action.payload.userId, action.payload.actionsHistoryId);
      // console.log("response from fetchNewActions ", response)
      if (response.error) {
        throw new Error(response.message);
      }
      yield put(apiResponseSuccess('ADD_NEW_ACTIONS', response));
    } catch (error) {
      yield put(apiResponseError('ADD_NEW_ACTIONS', error.message));
    }
  }      


function* editActions(action: any) {
  try {
    console.log("inside saga of edit actions about to call api", action.payload)
    const response = yield call(editActionsApi, action.payload);
    console.log("response from fetchNewActions ", response)
    if (response.error) {
      throw new Error(response.message);
    }
    yield put(apiResponseSuccess('EDIT_ACTIONS', response));
    yield call(showSuccessNotification, 'Action Details updaded successfully');
  } catch (error) {
    yield put(apiResponseError('EDIT_ACTIONS', error.message));
    yield call(showErrorNotification, 'Failed to update Action');
  }
}
  // function* watchRealTimeSSE(action: any) {
//     try {
//       // Start listening to SSE using the startListeningToSSE function
//       startListeningToSSE();
  
//       // Optionally, you could dispatch an action when SSE starts
//       // yield put(sseStartedAction());
  
//     } catch (error) {
//       console.error("SSE Error: ", error);
//       // Optionally handle SSE errors by dispatching an error action
//       yield put(apiResponseError('SSE_ERROR', error.message));
//     }
// }

// Declare eventSource variable at the top level so it can be accessed in both functions
let eventSource: EventSource | undefined;


const handleSSEMessage = (data: any) => {
  // global store
  console.log("handle SSE Message Received SSE data:", data);

  // Dispatch the UPDATE_ACTIONS_FROM_SSE action with the received data
  return data
};

const handleSSEError = (error: any) => {
console.error("SSE error:", error);
// Handle the error, possibly retry connection
};


const startListeningToSSE = (userId: number, historyId: number) => {
  console.log('startListeningToSSE called with:', { userId, historyId });

  // Create params object
  const params = { userId, historyId };

// Pass params to startSSE
  const eventSource = api.startSSE('get-my-actions', params, handleSSEMessage, handleSSEError);

  console.log('EventSource created:', eventSource);
  return eventSource;
};


const stopListeningToSSE = () => {
    if (eventSource) {
      api.stopSSE(eventSource);
      eventSource = undefined; // Clear the reference after stopping
    } else {
      console.warn("EventSource is already undefined.");
    }
};


function* watchRealTimeSSE(action: any) {
    const { userId, historyId } = action.payload;
    console.log("User id: ", userId)

    // Get actionsHistoryId from state using select effect
    console.log("HistoryID from state: ", historyId);

    const sseChannel = yield call(createSSEChannel, startListeningToSSE, stopListeningToSSE, userId, historyId);
    
    try {
        while (true) {
            const { type, data, error } = yield take(sseChannel);
            console.log('Received SSE type:', type); // Log the type
            console.log('Received SSE data:', data); // Log the data
            if (type === 'NEW_SSE_DATA') {
              console.log("new data received in saga", data)
              const processedData = handleSSEMessage(data); // Call handleSSEMessage to process data
              yield put({
                type: 'UPDATE_ACTIONS_FROM_SSE',
                payload: processedData,
              });
            } else if (type === 'SSE_ERROR') {
                yield put(apiResponseError('SSE_ERROR', error));
            } else if (type === 'SSE_CLOSED') {
                console.log('SSE connection closed by server');
                yield put({ type: 'SSE_CLOSED' });
                break;
            }
        }
    } catch (error) {
        yield put(apiResponseError('SSE_ERROR', error.message));
    } finally {
        if (yield cancelled()) {
            stopListeningToSSE();
        }
    }
}


function* stopRealTimeSSE(action: any) {
    try {
      // Stop listening to SSE using the stopListeningToSSE function
      stopListeningToSSE();
  
      // Optionally, you could dispatch an action when SSE stops
      // yield put(sseStoppedAction());
  
    } catch (error) {
      console.error("Error stopping SSE: ", error);
      // Optionally handle SSE stopping errors by dispatching an error action
      yield put(apiResponseError('SSE_STOP_ERROR', error.message));
    }
}


let sseTask;

function* manageSseSaga(action) {
    if (sseTask) {
        yield cancel(sseTask);
    }
    sseTask = yield fork(watchRealTimeSSE, action);
}

export default function* myActionSaga() {
  yield takeLatest('FETCH_NEW_ACTIONS_REQUEST', fetchNewActions);
  yield takeLatest('GET_REALTIME_SSE', watchRealTimeSSE);
  yield takeLatest('STOP_REALTIME_SSE', stopRealTimeSSE);  // Assuming you have an action to stop SSE  
  yield takeLatest('EDIT_ACTIONS', editActions);
}