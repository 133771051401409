import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { debugLogsTypes } from './debugLogsTypes';
import {
  contactsApiResponseSuccess,
  contactsApiResponseError,
} from './debugLogsActions';
import {
    getLogs as getLogsApi
} from '../../api/debugLog';




// Saga to handle fetching contacts
function* getLogs() {
  try {
    console.log("about to fetch GET logs through api")
    const response: Promise<any> = yield call(getLogsApi);
    console.log("response from GET logs ", response)
    yield put(
      contactsApiResponseSuccess(debugLogsTypes.GET_LOGS, response)
    );
  } catch (error: any) {
    yield put(contactsApiResponseError(debugLogsTypes.GET_LOGS, error));
  }
}




// Root saga to watch for the actions
export default function* debugLogsSaga() {
  yield takeLatest(debugLogsTypes.GET_LOGS, getLogs);
}
