import { AuthLoginActionTypes, AuthLoginState } from './authTypes';

export const INIT_STATE: AuthLoginState = {
  user: null,
  error: '',
  loading: false,
  isUserLogin: false,
  isUserLogout: true,
  isCaptchaVerified: false,
  isGmailPermissionProvided: false,
  captchaRetries: 0,
  userName: '',
  images: ''
};

const Login = (state = INIT_STATE, action: any): AuthLoginState => {
  switch (action.type) {
    case AuthLoginActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
          return {
            ...state,
            user: action.payload.data.user,
            loading: false,
            isUserLogin: true,
            isUserLogout: false,
          };
        case AuthLoginActionTypes.GOOGLE_VERIFY:
          console.log("google verification:",  action.payload.data.user.full_name);
          return {
            ...state,
            userName: action.payload.data.user.full_name,
            loading: false,
            isUserLogin: true,
            isUserLogout: false,
          };
        case AuthLoginActionTypes.GMAIL_VERIFY:
          console.log("inside success gmail verify reducer ", action.payload.data.user.full_name);
          console.log("inside success images", action.payload.data.user.images);          
          return {
            ...state,
            isGmailPermissionProvided: Boolean(action.payload.data.isPermitted),
            loading: false,
            userName:action.payload.data.user.full_name,
            images: action.payload.data.user.images,
            user: action.payload.data.user
          };
        case AuthLoginActionTypes.CAPTCHA_VERIFY:
          return {
            ...state,
            isCaptchaVerified: true,
            captchaRetries: 0,
          };
        case AuthLoginActionTypes.LOGOUT_USER:
          console.log("Logout message received : ", action.payload)
          return {
            ...state,
            loading: false,
            isUserLogin: false,
            isUserLogout: true,
            user: null,
            userName: ''
          };
        default:
          return { ...state };
      }

    case AuthLoginActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case AuthLoginActionTypes.LOGIN_USER:
          return {
            ...state,
            error: action.payload.error,
            isUserLogin: false,
            loading: false,
          };
        case AuthLoginActionTypes.GOOGLE_VERIFY:
          return {
            ...state,
            error: action.payload.error,
            isUserLogin: false,
            loading: false,
          };
        case AuthLoginActionTypes.GMAIL_VERIFY:
          return {
            ...state,
            error: action.payload.error,
            isGmailPermissionProvided: false,
            loading: false,
          };   
        case AuthLoginActionTypes.CAPTCHA_VERIFY:
          return {
            ...state,
            isCaptchaVerified: false,
            captchaRetries: state.captchaRetries + 1,
          };
        case AuthLoginActionTypes.LOGOUT_USER:
          console.log("Logout message received : ", action.payload)
          return {
            ...state,
            loading: false
          };
        default:
          return { ...state };
      }

    case AuthLoginActionTypes.LOGIN_USER:
      return {
        ...state,
        loading: true,
        isUserLogin: false,
      };
      
    case AuthLoginActionTypes.CAPTCHA_VERIFY:
      return {
        ...state,
        isCaptchaVerified: false,
        captchaRetries: 0,
      };
    case AuthLoginActionTypes.GMAIL_VERIFY:
      return {
        ...state,
        isGmailPermissionProvided: false,
        captchaRetries: 0,
        loading: true
      };        
    case AuthLoginActionTypes.LOGOUT_USER:
      return {
        ...state,
        loading: false,
        isUserLogout: false,
      };
      
    default:
      return { ...state };
  }
};

export default Login;
