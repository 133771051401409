// contactsTypes.ts



export const debugLogsTypes = {
  API_RESPONSE_SUCCESS: 'API_RESPONSE_SUCCESS',
  API_RESPONSE_ERROR: 'API_RESPONSE_ERROR',
  GET_LOGS : 'GET_LOGS'
};



// Follow-up config structure
export interface DebugLogs {
  logs: Record<string, any>;
  loading: boolean,
  error: any
}

