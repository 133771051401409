import { all } from 'redux-saga/effects';
import authSaga from '../auth/authSaga'; // Import your auth saga
// import LayoutSaga from '../layout/saga';
import layoutSaga from '../layout/saga';
import maiActionSaga from '../maiAction/maiActionSaga'; // Import your actions saga
import contactsSaga from '../contacts/contactSaga';
import debugLogsSaga from '../DebugLogs/debugLogsSaga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        layoutSaga(),
        maiActionSaga(),
        contactsSaga(),
        debugLogsSaga()
        // Add more sagas here if needed
    ]);
}
