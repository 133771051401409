import React, { createContext, useContext, useState, useCallback, ReactNode } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

interface NotificationContextProps {
  showSuccessNotification: (message: string) => void;
  showErrorNotification: (message: string) => void;
}

// Create the Notification context
const NotificationContext = createContext<NotificationContextProps | undefined>(undefined);

// Local variables to hold the functions so they can be exported globally
let showSuccessNotification: (message: string) => void;
let showErrorNotification: (message: string) => void;

export const NotificationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [toastBg, setToastBg] = useState<'success' | 'danger'>('success');

  // Function to show success notifications
  const internalShowSuccessNotification = useCallback((message: string) => {
    setToastMessage(message);
    setToastBg('success');
    setShow(true);
  }, []);

  // Function to show error notifications
  const internalShowErrorNotification = useCallback((message: string) => {
    setToastMessage(message);
    setToastBg('danger');
    setShow(true);
  }, []);

  // Initialize global variables for external use
  showSuccessNotification = internalShowSuccessNotification;
  showErrorNotification = internalShowErrorNotification;

  const handleClose = () => setShow(false);

  return (
    <NotificationContext.Provider value={{ showSuccessNotification: internalShowSuccessNotification, showErrorNotification: internalShowErrorNotification }}>
      {children}
      <ToastContainer position="top-end" className="p-3">
        <Toast show={show} onClose={handleClose} delay={5000} autohide  className="stylish-toast">
          <Toast.Header>
            <strong className="me-auto">{toastBg === 'success' ? 'Success' : 'Error'}</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </NotificationContext.Provider>
  );
};

// Custom hook to use notification context if needed
export const useNotification = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error('useNotification must be used within a NotificationProvider');
  }
  return context;
};

// Export the notification functions for global use
export { showSuccessNotification, showErrorNotification };
