// constants
import { BackendActionTypes } from './constants';

export type BackendActionType = {
    type:
        | BackendActionTypes.API_RESPONSE_SUCCESS
        | BackendActionTypes.API_RESPONSE_ERROR
        | BackendActionTypes.GET_NODE_ENV_VARIABLES
        | BackendActionTypes.GET_NODE_BOILERPLATE
    payload: {} | string;
};

type UserData = {
    id: number;
    email: string;
    username: string;
    password: string;
    firstName: string;
    lastName: string;
    role: string;
    token: string;
};

// common success
export const backendApiResponseSuccess = (actionType: string, data: UserData | {}): BackendActionType => ({
    type: BackendActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const backendApiResponseError = (actionType: string, error: string): BackendActionType => ({
    type: BackendActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

// export const getStockChatTitles = (uid : string | number) => ({
//     type: StockChatsActionTypes.GET_STOCK_CHAT_TITLES,
//     payload: { uid }
//   });

// export const getStockChatMessages = (data: object) => ({
//     type: StockChatsActionTypes.GET_STOCK_CHAT_MESSAGES,
//     payload: { data } 
// });

export const getNodeEnvVariables = (data: object)=> ({
    type: BackendActionTypes.GET_NODE_ENV_VARIABLES,
    payload: { data } ,
});


export const getNodeBoilerplate = (data: object) => ({
    type: BackendActionTypes.GET_NODE_BOILERPLATE,
    payload: { data } ,
});
