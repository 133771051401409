// contactsReducer.ts

import { ContactsActionTypes, ContactsState } from './contactTypes';

export const INIT_STATE: ContactsState = {
  contacts: {
    // "2":{
    //   "contact_id": "2",
    //   "email": "shubham@dkafka.com",
    //   "mobile_no": "1234567890",
    //   "first_name": "Vikas",
    //   "display_name": "Vikas Varfa",
    //   "domain": "",
    //   "user_id": "1",
    //   "contact_type": "peer",
    //   "scheduler_id": ""
    // }
  }, 
  followUpConfig: {
    "contact_type_rule": [
      {
        "contact_type": "employee",
        "tags": [""], 
        "followup_mail_type": "draft" 
      },
      {
        "contact_type": "client",
        "tags": [""],
        "followup_mail_type": "sent"
      },
      {
        "contact_type": "peers",
        "tags": [""],
        "followup_mail_type": "sent"
      },
      {
        "contact_type": "self",
        "tags": [""],
        "followup_mail_type": "sent"
      },
      {
        "contact_type": "contacts",
        "tags": [""],
        "followup_mail_type": "sent"
      }
    ],
    "individual_contact_rule": [
      {
        "contact_id": 1,
        "tags": ["day_end"],
        "followup_mail_type": "sent"
      }
    ],
    "tags_to_time_mapping": {
      "day_start_time": "08:00:00",
      "day_end_time": "17:00:00",
      "time_zone": "America/New_York",
      "week_start_day": "Monday",
      "week_end_day": "Friday"
    },
    rule_json: undefined
  },
  loading: false,
  error: '',
};

const contactReducer = (state = INIT_STATE, action: any): ContactsState => {
  switch (action.type) {
    // Success handler for API responses
    
    case ContactsActionTypes.API_RESPONSE_SUCCESS:
      console.log("contract reducer success response")
      switch (action.payload.actionType) {
        case ContactsActionTypes.GET_CONTACTS:
          console.log("get api success reducer trigerred ", action.payload.data.contacts)
          return {
            ...state,
            contacts: action.payload.data.contacts,
            loading: false,
            error: '',
          };

        case ContactsActionTypes.UPDATE_CONTACT_TYPE:
          const { contact_id, contact_type } = action.payload.data.data;
          console.log("success api for update_contact_type : ", action.payload.data.data)
          console.log("success api for update_contact_type : ", contact_id, contact_type )
          return {
            ...state,
            contacts: {
              ...state.contacts,
              [contact_id]: {
                ...state.contacts[contact_id],
                contact_type: contact_type,
              },
            },
            loading: false,
            error: '',
          };

        case ContactsActionTypes.SET_FOLLOWUP_CONFIG:
          console.log("setfollowupconfig post api reducer triggerred", action.payload.data.ruleJson)
          return {
            ...state,
            followUpConfig: action.payload.data.ruleJson,
            loading: false,
            error: '',
          };

        case ContactsActionTypes.GET_FOLLOWUP_CONFIG:  // New case for getting follow-up config
          console.log("getfollowupconfig post api reducer triggerred", action.payload.data.data.ruleJson)
          return {
            ...state,
            followUpConfig: action.payload.data.data.ruleJson,
            loading: false,
            error: '',
          };

        default:
          return { ...state };
      }

    // Error handler for API responses
    case ContactsActionTypes.API_RESPONSE_ERROR:
      console.log("contract reducer error response")
      return {
        ...state,
        loading: false,
        error: typeof action.payload.error === 'string' ? action.payload.error : "An error occurred in Contact reducer response",
      };

    // Actions to set loading state before API call
    case ContactsActionTypes.GET_CONTACTS:
      console.log(" get contacts is pre api is triggerred ")
      return { ...state };
    case ContactsActionTypes.UPDATE_CONTACT_TYPE:
      console.log("update contact type is pre api is triggerred ", action.payload);
      return { 
        ...state,
        loading: true,
      };
    case ContactsActionTypes.SET_FOLLOWUP_CONFIG:
      console.log("contacts pre api reducer triggered")
      return {
        ...state,
        loading: true,
        error: '',
      };
    case ContactsActionTypes.GET_FOLLOWUP_CONFIG:  // Loading state before fetching follow-up config
      return {
        ...state,
        loading: true,
        error: '',
      };
    default:
      return { ...state };
  }
};

export default contactReducer;